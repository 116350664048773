<template>
  <el-upload
    :file-list="list"
    :limit="limit"
    :accept="accept"
    :multiple="multiple"
    action=""
    :on-remove="handleRemove"
    :before-remove="beforeRemove"
    :before-upload="beforerUpload"
    :on-preview="handlePreview"
    :on-success="handleSuccess"
    :http-request="uploadData"
  >
    <el-button class="btn-uplaod" size="mini" type="primary">{{ btntext }}</el-button>
    <div slot="tip" class="el-upload__tip btn-tip">{{ tip }}</div>
  </el-upload>
</template>

<script>
import { uploadFile } from '@/api/api_common'

export default {
  props: {
    // 上传文件的设置字段
    params: {
      type: Object,
      default: null
    },
    // 上传的文件列表
    fileList: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 最大允许上传个数
    limit: {
      type: Number,
      default: 100
    },
    // 接受上传的文件类型
    accept: {
      type: String,
      default: '.pem,.xls,.xlsx,.txt,.doc,.pdf,.PDF,image/*'
    },
    // 是否支持多选文件
    multiple: {
      type: Boolean,
      default: false
    },
    // 接受收的提示性文字
    tip: {
      type: String,
      default: ''
    },
    // 上传按钮文字
    btntext: {
      type: String,
      default: '文件上传'
    }
  },
  computed: {
    list: {
      get() {
        return this.fileList
      },
      set(val) {
        this.$emit('update:fileList', val)
      }
    }
  },
  methods: {
    // 上传文件之前的钩子
    beforerUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      const acceptType = this.accept.split(',')
      let filetype = false
      for (const item of acceptType) {
        if (file.name.endsWith(item)) {
          filetype = true
        }
      }
      // const filetype = file.name.endsWith('.map3') || file.name.endsWith('.wmv')
      if (!filetype) {
        this.$message.error('应上传 mp3/wmv 文件!')
      }
      return isLt10M && filetype
    },
    // 文件上传时的勾子
    uploadData(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      uploadFile(formData).then(res => {
        const response = { name: res.fileOriginalName, url: res.rootPath, id: res.id, uid: data.file.uid }
        this.list.push(response)
      })
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      // this.list.splice(0, this.list.length)
      // this.list = this.list.concat(fileList)
      this.list = fileList
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      // console.log(file)
      if (file.url) {
        // console.log(file.url)
      }
    },
    beforeRemove() {},
    handleSuccess() {}
  }
}
</script>

<style lang="scss" scoped>
.btn-tip {
	display: inline-block;
	margin-left: 10px;
}
.el-upload__tip{
  margin-top: 0;
}
</style>
